// Styles

// Main styles

@import "./config/default/bootstrap.scss";
@import "./config/default/app.scss";

// Minton Icons
@import "./icons.scss";

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
